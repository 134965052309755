body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: rgba(43, 15, 165, 0.5);
  box-shadow: 0 0 1px rgba(21, 88, 177, 0.5);
}

@media print {


  ul,li {
     page-break-inside: avoid!important; 
     break-inside: avoid!important; 
      /* white-space: nowrap; */
      
 };
 tr  { 
     display: table-row-group;
     page-break-inside:avoid; 
     page-break-after:auto;
     }

 td   { 
     display: table-cell;
     page-break-inside:avoid; 
     page-break-after:auto;
     }
 .table {
     page-break-inside: avoid!important; 
     break-inside: avoid!important; 
 }
 table >tr> td {
     
     page-break-inside: avoid!important; 
     break-inside: avoid!important; 
 }
  td {
     page-break-inside:avoid;
     background: "red"
   }
}

